import React from "react";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Container, Grid, MenuItem, TextField, Button, Paper, Typography, CircularProgress, Box } from '@material-ui/core';
import { LocationOnOutlined, TrainRounded, WarningRounded } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import { FormValidation } from "./services/FormValidation";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import PageTitle from "./PageTitle";
import PageSubtitle from "./PageSubtitle";
import clsx from  'clsx';

const useStyles = theme => ({
  fullWidth: {
    width: '100%'
  },
  pageNotification: {
    'background-color': '#f89406',
    padding: theme.spacing(2),
    'border-radius': '5px'
  },
  pageNotificationText: {
    color: '#193441',
    'font-size': '1rem'
  },
  hasIcon: {
    'vertical-align': 'middle',
    display: 'flex',
    'align-items': 'center',
    gap: '6px'
  }
});

class DefectEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: '',
      inspectionId: this.props.match.params.inspectionId,
      defectId: this.props.match.params.defectId,
      track_change_status: false,
      new_track_name: '',
      site: null,
      inspection: {},
      short_id: '',
      area: 0,
      priority: 0,
      defect_status: 0,
      asset_class: 0,
      kms_start: '',
      kms_finish: '',
      latitude: '',
      longitude: '',
      description: '',
      maintenance_required: '',
      date_reported: null,
      repair_due_date: null,
      repair_completed: null,
      reassessed_status: null,
      username: '',
      priorities: [],
      statuses: [],
      areas: [],
      asset_classes: [],
      isLoading: true,
      updated_properties: [],
      formFields: [ 'asset_class', 'area', 'priority', 'defect_status', 'kms_start', 'kms_finish', 'description', 'maintenance_required', 'repair_due_date', 'latitude', 'longitude' ],
      formErrors: {}
    };

    this._handleChange = this._handleChange.bind(this);
    this._formatKms = this._formatKms.bind(this);
    this._changeTrackRequest = this._changeTrackRequest.bind(this);
    this._handleRepairDateChange = this._handleRepairDateChange.bind(this);
    this._handleRepairCompletedChange = this._handleRepairCompletedChange.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleGpsUpdate = this._handleGpsUpdate.bind(this);
    this._geoLocationSuccess = this._geoLocationSuccess.bind(this);
    this._validateInput = this._validateInput.bind(this);
    
    Service.getAll('User').then((user) => {
      this.state.username = user[0].first_name + ' ' + user[0].last_name;
    });
  }

  _getData() {  

    Service.get('Inspections', parseInt(this.props.match.params.inspectionId)).then((inspection) => {
      const filteredDefects = inspection.defects.filter((defect) => defect.defect.id.toString() === this.props.match.params.defectId);

      let repair_due_date = filteredDefects[0].defect.repair_due_date ? filteredDefects[0].defect.repair_due_date.split('-') : null;
      
      if(repair_due_date)
        filteredDefects[0].defect.repair_due_date = new Date(filteredDefects[0].defect.repair_due_date);

      let repair_completed = filteredDefects[0].defect.repair_completed ? filteredDefects[0].defect.repair_completed.split('-') : null;
      
      if(repair_completed)
        filteredDefects[0].defect.repair_completed = new Date(filteredDefects[0].defect.repair_completed);
      
      const getDropdownData = Promise.all([
        Service.getAll('Priorities'),
        Service.getAll('Statuses'),
        Service.getAllFromIndex('Tracks', 'siteIndex', parseInt(inspection.site)),
        Service.getAllFromIndex('Assets', 'siteIndex', 0),
        Service.getAllFromIndex('Assets', 'siteIndex', parseInt(inspection.site))        
      ]);

      let defect = filteredDefects[0].defect;

      getDropdownData.then((values) => {

        let today = new Date();
        let repair_date = new Date(defect.repair_due_date);

        today.setHours(0,0,0,0);
        repair_date.setHours(0,0,0,0);

        // Do not update defect_status to Overdue if M or P4 or if Repair Date not set
        let test_overdue_state = ( defect.priority !== 6 /* M */) && ( defect.priority !== 5 /* P4 */) && ( defect.repair_due_date !== null ) && ( repair_date < today )
        var defect_overdue = false;
        
        if (( test_overdue_state ) && ( defect.defect_status !== 5 /* Overdue */))
          defect_overdue = true;
        
        if ( defect.defect_status !== 7 ) /* Only show if the Defect is already set to TBV */
          values[1].splice(values[1].findIndex(item => item.id === 7 /* To Be Verified */), 1)

        this.setState({
          site: inspection.site,
          inspection: inspection,
          track_change_status: filteredDefects[0].track_change_status,
          new_track_name: filteredDefects[0].new_track_name,
          id: defect.id,
          short_id: defect.short_id,
          asset_class: defect.asset_class,
          area: this.props.match.params.trackId,
          defect_status: defect_overdue ? 5 /* Overdue */ : defect.defect_status,
          priority: defect.priority,
          kms_start: Helper.formatKms(defect.kms_start) ? defect.kms_start : '',
          kms_finish: Helper.formatKms(defect.kms_finish) ? defect.kms_finish : '',
          latitude: defect.latitude ? defect.latitude : '',
          longitude: defect.longitude ? defect.longitude : '',
          description: defect.description ? defect.description : '',
          maintenance_required: defect.maintenance_required ? defect.maintenance_required : '',
          date_reported: defect.date_reported ? defect.date_reported : null,
          repair_due_date: defect.repair_due_date ? defect.repair_due_date : null,
          reassessed_status: filteredDefects[0].reassessed_status ? filteredDefects[0].reassessed_status : false,
          repair_completed: defect.repair_completed ? defect.repair_completed : null,
          app_tmp_id: defect.id,
          priorities: values[0],
          statuses: values[1],
          areas: values[2],
          asset_classes: values[3].concat(values[4]),
          isLoading: false,
          updated_properties: defect_overdue ? [ 'defect_status' ] : []
        });
      });
    });
  }

  componentDidMount() {
    this._getData();
  }

  _validateInput(name, value) {
    
    let errors = {};
    var testResult = false;
    
    switch ( name ) {
      case 'asset_class':
      case 'area':
      case 'priority':
      case 'defect_status':
        testResult = FormValidation.requireSelect(name, value);
        errors[name] = testResult ? "" : "You must select a value.";
        break;
      case 'kms_start':
        testResult = FormValidation.validateNumber(name, value) && FormValidation.requireText(name, value);
        errors[name] = testResult ? "" : "Kms Start is not valid.";
        break;
      case 'kms_finish':
        testResult = FormValidation.validateNumber(name, value) || value === '';
        errors[name] = testResult ? "" : "Kms Finish is not valid.";
        break;
      case 'repair_due_date':
        testResult = FormValidation.validateDate(name, value);
          
        errors[name] = testResult ? "" : "Repair Due Date is not valid.";
        
        if ((testResult) && (parseInt(this.state.defect_status) !== 6 /* Verified Complete */) && (parseInt(this.state.defect_status) !== 5 /* Overdue */) && (parseInt(this.state.defect_status) !== 7 /* To Be Verified */)) {
          let validationStatus = FormValidation.validateDefectRepairDueDate(value, this.state.priority);
          testResult = validationStatus['status'];
          errors[name] = testResult ? "" : validationStatus['msg'];
        }
          
        break;
      case 'repair_completed':
        testResult = FormValidation.validateDate(name, value);          
        errors[name] = testResult ? "" : "Repaired Date is not valid.";
        break;
      case 'description':
        testResult = FormValidation.requireTextArea(name, value);
        errors[name] = testResult ? "" : "Description cannot be empty.";
        break;
      case 'latitude':
      case 'longitude':
        testResult = FormValidation.validateNumber(name, value) || value === '';
        errors[name] = testResult ? "" : "Not a valid coordinate value.";
        break;
      case 'maintenance_required':
        testResult = true;
        errors[name] = "";
        break;
      default:
        
    }

    return errors;
  }

  _formatKms(e) {
    const { name, value } = e.target;
    this.setState({ [name]: Helper.formatKms(value) });
  }

  _handleChange(e) {
    const { name, value } = e.target;
    let error = this._validateInput(name, value);
  
    let inspection = this.state.inspection;
    const defectIdx = inspection.defects.findIndex((defect) => defect.defect.id.toString() === this.props.match.params.defectId);      
    const oldDefect = inspection.defects[defectIdx].defect;

    if (( name === 'kms_start' ) || ( name === 'kms_finish' ))
      this.setState({ [name]: value, updated_properties: [...new Set(this.state.updated_properties.concat(name))], formErrors: { ...this.state.formErrors, [name]: error[name] } });
    else if ( name === 'defect_status' ) {
      if (value === 6) { // Verified Complete
        this.setState({ [name]: value, repair_completed: new Date(), updated_properties: [...new Set(this.state.updated_properties.concat(name, 'repair_completed'))] });
      } else {
        if ( this.state.repair_due_date === null && oldDefect['defect_status'] === 7 /* TBV */ && value !== 7 ) {
          let repair_due_date = Helper.priorityBasedRepairDate(this.state.priority);
          this.setState({ [name]: value, repair_due_date: repair_due_date, updated_properties: [...new Set(this.state.updated_properties.concat('repair_due_date', name))], formErrors: { ...this.state.formErrors, [name]: error[name], 'repair_due_date': "" } });
        } else         
          this.setState({ [name]: value, updated_properties: [...new Set(this.state.updated_properties.concat(name))], formErrors: { ...this.state.formErrors, [name]: error[name] } });
      }
    } else if ( name === 'priority' ) {

      var update_defect_status = (this.state.defect_status === 5 /* Overdue */) ? true : false;

      if (this.state.repair_due_date === null) { 
        if ((value !== 5 /* P4 */ ) && (value !== 6 /* M */ )) {
          let repair_due_date = Helper.priorityBasedRepairDate(value);
          
          if ( update_defect_status === true ) // Change defect_status to Valid
            this.setState({ [name]: value, defect_status: 2 /* Valid */, repair_due_date: repair_due_date, updated_properties: [...new Set(this.state.updated_properties.concat('repair_due_date', 'defect_status', name))], formErrors: { ...this.state.formErrors, [name]: error[name], 'defect_status': '', 'repair_due_date': "" } });
          else
            this.setState({ [name]: value, repair_due_date: repair_due_date, updated_properties: [...new Set(this.state.updated_properties.concat('repair_due_date', name))], formErrors: { ...this.state.formErrors, [name]: error[name], 'repair_due_date': "" } });
        } else {          
          if ( update_defect_status === true ) // Change defect_status to Valid
            this.setState({ [name]: value, defect_status: 2 /* Valid */, updated_properties: [...new Set(this.state.updated_properties.concat('defect_status', name))], formErrors: { ...this.state.formErrors, [name]: error[name], 'defect_status': '' } });
          else
            this.setState({ [name]: value, updated_properties: [...new Set(this.state.updated_properties.concat(name))], formErrors: { ...this.state.formErrors, [name]: error[name] } });      
        }
      } else { // Repair Due Date !== null
        if ((value !== 5 /* P4 */ ) && (value !== 6 /* M */ )) {
          let repair_due_date = Helper.priorityBasedRepairDate(value);
          
          if ( update_defect_status === true ) // Change defect_status to Valid
            this.setState({ [name]: value, defect_status: 2 /* Valid */, repair_due_date: repair_due_date, updated_properties: [...new Set(this.state.updated_properties.concat('repair_due_date', 'defect_status', name))], formErrors: { ...this.state.formErrors, [name]: error[name], 'defect_status': '', 'repair_due_date': "" } });
          else
            this.setState({ [name]: value, repair_due_date: repair_due_date, updated_properties: [...new Set(this.state.updated_properties.concat('repair_due_date', name))], formErrors: { ...this.state.formErrors, [name]: error[name], 'repair_due_date': "" } });      
        } else {          
          if ( update_defect_status === true ) // Change defect_status to Valid
            this.setState({ [name]: value, defect_status: 2 /* Valid */, repair_due_date: null, updated_properties: [...new Set(this.state.updated_properties.concat('repair_due_date', 'defect_status', name))], formErrors: { ...this.state.formErrors, [name]: error[name], 'defect_status': '', 'repair_due_date': "" } });
          else
            this.setState({ [name]: value, repair_due_date: null, updated_properties: [...new Set(this.state.updated_properties.concat('repair_due_date', name))], formErrors: { ...this.state.formErrors, [name]: error[name], 'repair_due_date': "" } });      
        }
      }
    } else
      this.setState({ [name]: value, updated_properties: [...new Set(this.state.updated_properties.concat(name))], formErrors: { ...this.state.formErrors, [name]: error[name] } });
  }

  _changeTrackRequest() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId + '/track-change');
  }

  _handleRepairDateChange(dateObject) {
    let error = this._validateInput('repair_due_date', dateObject);
    this.setState({ repair_due_date: dateObject, updated_properties: [...new Set(this.state.updated_properties.concat('repair_due_date'))], formErrors: { ...this.state.formErrors,'repair_due_date': error['repair_due_date'] } });
  }

  _handleRepairCompletedChange(dateObject) {
    let error = this._validateInput('repair_completed', dateObject);
    this.setState({ repair_completed: dateObject, updated_properties: [...new Set(this.state.updated_properties.concat('repair_completed'))], formErrors: { ...this.state.formErrors,'repair_completed': error['repair_completed'] } });
  }

  _geoLocationSuccess(position) {

    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    this.setState({
      latitude: latitude,
      longitude: longitude
    });
  }

  _handleGpsUpdate() {    

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      alert('Unable to get your location. Please make sure you have location services enabled.');
    }

    Helper.getCurrentLocation(this._geoLocationSuccess, error);
  }

  _handleCancel() {
    if (this.state.updated_properties.length > 0) {
      if ( window.confirm('Are sure you want to cancel all changes?') === true ) 
        this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId);
      else
        return false;
    } else
      this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId);
  }

  _handleSave() {
    let errors = FormValidation.validateForm(this.state.formFields, this.state, this._validateInput);
    
    if( errors.count === 0 ) {      
      let inspection = this.state.inspection;
      let defect = {
        id: this.state.id,
        short_id: this.state.short_id,
        asset_class: this.state.asset_class,
        area: this.state.area,
        defect_status: this.state.defect_status,
        priority: this.state.priority,
        kms_start: Helper.formatKms(this.state.kms_start),
        kms_finish: Helper.formatKms(this.state.kms_finish),
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        description: this.state.description,
        maintenance_required: this.state.maintenance_required,
        date_reported: this.state.date_reported,
        repair_due_date: this.state.repair_due_date,
        repair_completed: this.state.repair_completed,
        app_tmp_id: this.state.id
      };
      
      const defectIdx = inspection.defects.findIndex((defect) => defect.defect.id.toString() === this.props.match.params.defectId);      
      const oldDefect = inspection.defects[defectIdx].defect;

      inspection.defects[defectIdx].defect = defect;

      var repair_due_date = this.state.repair_due_date;
      
      if (repair_due_date instanceof Date && !isNaN(repair_due_date)) {
        repair_due_date = this.state.repair_due_date.getFullYear() + '-' + (this.state.repair_due_date.getMonth() + 1).toString().padStart(2, '0') + '-' + (this.state.repair_due_date.getDate()).toString().padStart(2, '0');
        inspection.defects[defectIdx].defect.repair_due_date = repair_due_date;
        defect.repair_due_date = repair_due_date;
      }

      var repair_completed = this.state.repair_completed;
      
      if (repair_completed instanceof Date && !isNaN(repair_completed)) {
        repair_completed = this.state.repair_completed.getFullYear() + '-' + (this.state.repair_completed.getMonth() + 1).toString().padStart(2, '0') + '-' + (this.state.repair_completed.getDate()).toString().padStart(2, '0');
        inspection.defects[defectIdx].defect.repair_completed = repair_completed;
        defect.repair_completed = repair_completed;
      }

      Service.put('Inspections', inspection);

      if (defect.short_id === 'PENDING') {
        let uuid = inspection.defects[defectIdx].uuid;
        const dbKey = Service.getKeyFromIndex('Changelog', 'uniqueId', uuid);
        
        dbKey.then((key) => {
          Service.put('Changelog', {
            uuid: uuid,
            change_type: 'newdefect',
            action_datetime: new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19), // UTC datetime for consistency with API
            inspection: this.props.match.params.inspectionId,
            defect: this.state.id,
            asset_class: parseInt(this.state.asset_class),
            area: parseInt(this.state.area),
            defect_status: parseInt(this.state.defect_status),
            kms_start: Helper.formatKms(this.state.kms_start),
            kms_finish: Helper.formatKms(this.state.kms_finish),
            priority: parseInt(this.state.priority),
            description: this.state.description,
            maintenance_required: this.state.maintenance_required,
            repair_due_date: repair_due_date,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            app_tmp_id: this.state.id,
            imgdata: []
          }, key).then((status) => {
            this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId);
          });
        });
      } else {
        const defectChanges = Promise.all([
          this.state.updated_properties.map((property) => {
            
            if ( this.state[property] !== oldDefect[property] ) {
              
              if (( property !== 'latitude' ) && ( property !== 'longitude' ))
                inspection.defects[defectIdx].defect_changed = true;
              
              const defectUpdateKey = Service.getKeyFromIndex('Changelog', 'DefectUpdates', [ 'defect_update', this.props.match.params.defectId, property ]);

              defectUpdateKey.then((key) => {
                Service.put('Changelog', {
                  uuid: Helper.generateUUID(),
                  change_type: 'defect_update',
                  action_datetime: new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19), // UTC datetime for consistency with API
                  inspection: this.props.match.params.inspectionId,
                  defect: this.props.match.params.defectId,
                  item: property,
                  value: defect[property]
                }, key);
              });

              if (( property === 'defect_status' ) || ( property === 'priority' )) {
                
                inspection.defects[defectIdx].manually_reassessed = true;

                Service.put('Inspections', inspection);
              
              }
            }
            return true;
          })
        ]);

        defectChanges.then((status) => {
          this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId);
        });
      }
    } else {
      this.setState({ formErrors: { ...errors.msgs }});
      alert('This form has errors.');
    }
  }

  render() {
    
    let { inspection, priorities, statuses, areas, asset_classes, track_change_status } = this.state;
    const { classes } = this.props;

    if (!this.state.site) {
      return <CircularProgress />;
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }

    return (
      <Container spacing={3}>
        <Grid container spacing={3}>
          <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
          <PageSubtitle object_type="defect" object_id={ this.state.short_id !== 'PENDING' ? this.state.short_id : this.state.inspection.client_short_name + '-' + this.state.id + '(T)' } page_action={ 'Edit' } />
        </Grid>
        {
          ( track_change_status ) ? <Box mt={2} mb={3}>
              <Paper className={ classes.pageNotification }>
                <Grid item xs={12}>                  
                  <Typography variant="body2" component="p" className={ clsx( classes.hasIcon, classes.pageNotificationText ) }><WarningRounded /> You have requested the Track be changed to <b>{ this.state.new_track_name }</b></Typography>
                </Grid>
              </Paper>
            </Box> : ''
        }
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
                name="area"
                value={ parseInt(this.state.area) }
                onChange={ this._handleChange }
                label="Track"
                {...(this.state.formErrors["area"] && { error: true, helperText: this.state.formErrors["area"] })}                                    
              >
                <MenuItem value="0">
                  <em>None</em>
                </MenuItem>
                {
                  areas.map(i => <MenuItem value={ i.id } key={ "area-" + i.id }>{ i.name }</MenuItem>)
                }
              </TextField>
            </Grid>
            {
              ( !this.state.track_change_status ) ?
                <Grid item xs={12}>
                  <Button variant="contained" color="default" fullWidth size="large" onClick={ () => this._changeTrackRequest() }><TrainRounded />&nbsp; Request Track Change</Button>
                </Grid> : ''
            }
            <Grid item xs={12}>
              <TextField
                select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                margin="normal"
                fullWidth
                disabled={ this.state.defect_status === 1 }
                name="defect_status"
                value={ parseInt(this.state.defect_status) }
                onChange={ this._handleChange }
                label="Status"
                {...(this.state.formErrors["defect_status"] && { error: true, helperText: this.state.formErrors["defect_status"] })}
              >
                {
                  ( this.state.defect_status === 1 ) ? 
                    <MenuItem value="1">
                      Pending
                    </MenuItem>
                  : <MenuItem value="0">
                    <em>None</em>
                  </MenuItem>
                }
                {
                  ( this.state.defect_status !== 1 ) ? 
                  statuses.map(i => {
                    if( i.id !== 1 /* Pending */) 
                      return (<MenuItem value={ i.id } key={ "status-" + i.id }>{ i.name }</MenuItem>)
                    else
                      return('')
                  }) 
                  : ''
                }
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                margin="normal"
                fullWidth
                name="asset_class"
                value={ parseInt(this.state.asset_class) }
                onChange={ this._handleChange }
                label="Asset Class"
                {...(this.state.formErrors["asset_class"] && { error: true, helperText: this.state.formErrors["asset_class"] })}
              >
                <MenuItem value="0">
                  <em>None</em>
                </MenuItem>
                {
                  asset_classes.map(i => <MenuItem value={ i.id } key={ "asset-" + i.id }>{ i.name }</MenuItem>)
                }
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                margin="normal"
                fullWidth
                name="priority"
                value={ parseInt(this.state.priority) }
                onChange={ this._handleChange }
                label="Priority"
                {...(this.state.formErrors["priority"] && { error: true, helperText: this.state.formErrors["priority"] })}
              >
                <MenuItem value="0">
                  <em>None</em>
                </MenuItem>
                {
                  priorities.map(i => <MenuItem value={ i.id } key={ "priority-" + i.id }>{ i.name }</MenuItem>)
                }
              </TextField>
            </Grid>            
            <Grid item xs={6}>
              <TextField
                id="outlined-textarea"
                label="Latitude"
                variant="outlined"
                margin="normal"
                ref={ this.formLatitude }
                name="latitude"
                onChange={ this._handleChange }
                value={ this.state.latitude }
                {...(this.state.formErrors["latitude"] && { error: true, helperText: this.state.formErrors["latitude"] })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-textarea"
                label="Longitude"
                variant="outlined"
                margin="normal"
                ref={ this.formLongitude }
                name="longitude"
                onChange={ this._handleChange }
                value={ this.state.longitude }
                {...(this.state.formErrors["longitude"] && { error: true, helperText: this.state.formErrors["longitude"] })}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" fullWidth size="large" onClick={ this._handleGpsUpdate }><LocationOnOutlined /> Use Current Location</Button>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-textarea"
                label="Start (kms)"
                variant="outlined"
                margin="normal"
                ref={ this.formKmsStart }
                name="kms_start"
                value={ this.state.kms_start }
                onChange={ this._handleChange }
                onBlur={ this._formatKms }
                {...(this.state.formErrors["kms_start"] && { error: true, helperText: this.state.formErrors["kms_start"] })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-textarea"
                label="End (kms)"
                variant="outlined"
                margin="normal"
                ref={ this.formKmsEnd }
                name="kms_finish"
                value={ this.state.kms_finish }
                onChange={ this._handleChange }
                onBlur={ this._formatKms }
                {...(this.state.formErrors["kms_finish"] && { error: true, helperText: this.state.formErrors["kms_finish"] })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-textarea"
                label="Description"
                variant="outlined"
                margin="normal"
                multiline
                minRows={4}
                ref={ this.formDescription }
                name="description"
                value={ this.state.description }
                onChange={ this._handleChange }
                fullWidth
                {...(this.state.formErrors["description"] && { error: true, helperText: this.state.formErrors["description"] })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-textarea"
                label="Maintenance Required"
                variant="outlined"
                margin="normal"
                multiline
                minRows={4}
                ref={ this.formMaintenanceRequired }
                name="maintenance_required"
                value={ this.state.maintenance_required }
                onChange={ this._handleChange }
                fullWidth
                {...(this.state.formErrors["maintenance_required"] && { error: true, helperText: this.state.formErrors["maintenance_required"] })}
              />
            </Grid>
            <Grid item xs={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  margin="normal"
                  fullWidth
                  InputAdornmentProps={{ position: "start"}}
                  id="date-picker-dialog"
                  label="Repair Due"
                  format="dd/MM/yyyy"
                  value={ this.state.repair_due_date }
                  onChange={ this._handleRepairDateChange }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputProps={{ readOnly: true }} 
                  disabled={ ([ 5 /* P4 */, 6 /* M */ ].includes(this.state.priority)) || (this.state.defect_status === 7 /* Status: To Be Verified */) ? true : false }
                  {...(this.state.formErrors["repair_due_date"] && { error: true, helperText: this.state.formErrors["repair_due_date"] })}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  margin="normal"
                  fullWidth
                  InputAdornmentProps={{ position: "start" }}
                  id="date-picker-dialog"
                  label="Repaired"
                  format="dd/MM/yyyy"
                  value={ this.state.repair_completed }
                  onChange={ this._handleRepairCompletedChange }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputProps={{ readOnly: true }} 
                  {...(this.state.formErrors["repair_completed"] && { error: true, helperText: this.state.formErrors["repair_completed"] })}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth size="large" onClick={ this._handleSave }>Save</Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="default" fullWidth size="large" onClick={ this._handleCancel }>Cancel</Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

export default withRouter(withStyles(useStyles)(DefectEdit));